<template>
  <div
    class="paragraph paragraph-text-image layout-grid mx-auto max-w-screen-xxxl"
    :class="displayClass"
  >
    <MediaImage
      v-if="image"
      v-blokkli-droppable:field_media_image
      v-bind="image"
      :image-style="imageStyle"
      class="grid-area-m aspect-video"
      img-class="w-full h-full object-cover"
    />
    <h2
      v-blokkli-editable:field_headline
      class="grid-area-t typo-headline-4 mb-24 uppercase"
      :class="offsetPadding"
      v-text="headline"
    />
    <div class="grid-area-c justify flex flex-col" :class="offsetPadding">
      <div
        v-blokkli-editable:field_description="{ type: 'frame' }"
        class="typo-body-2 rich-text mb-24 md:mb-24"
        :class="{
          'lg:mb-0': options.display === 'left' || options.display === 'right',
        }"
        v-html="description"
      />

      <ButtonsLinkButton
        v-if="url"
        class="link-button mt-28 mobile-only:mb-40"
        :class="
          ['aligned', 'vertical', 'horizontal'].includes(options.display) &&
          'md:mb-48'
        "
        :url="url"
      >
        {{ $texts('readMore', 'Read more') }}
      </ButtonsLinkButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type ParagraphTextImageFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'text_image',
  options: {
    display: {
      type: 'radios',
      label: 'Display',
      default: 'horizontal',
      displayAs: 'icons',
      options: {
        horizontal: {
          icon: 'icon-blokkli-option-text-image-horizontal',
          label: 'Horizontal',
        },
        left: {
          icon: 'icon-blokkli-option-text-image-left',
          label: 'Left',
        },
        right: {
          icon: 'icon-blokkli-option-text-image-right',
          label: 'Right',
        },
        aligned: {
          icon: 'icon-blokkli-option-text-image-aligned',
          label: 'Aligned',
        },
        vertical: {
          icon: 'icon-blokkli-option-text-image-vertical',
          label: 'Vertical',
        },
      },
    },
  },
  editor: {
    addBehaviour: 'no-form',
  },
})

const baseImageStyle = {
  type: 'sizes' as const,
  aspectRatio: 16 / 9,
  sizes: {
    sm: 780, // 100%
    md: 660, // 65%
    lg: 680, // 57%
    xl: 810, // 57%
    xxl: 1380, // 57%
    xxxl: 1380, // todo: load max size image
  },
}

const imageStyleLeftRightHorizontal = defineImageStyle(baseImageStyle)
const imageStyleAligned = defineImageStyle({
  ...baseImageStyle,
  sizes: {
    ...baseImageStyle.sizes,
    xl: 641,
    xxl: 760,
  },
})
const imageStyleVertical = defineImageStyle({
  ...baseImageStyle,
  sizes: {
    ...baseImageStyle.sizes,
    lg: 780,
    xl: 900,
    xxl: 1380,
  },
})

const imageStyle = computed(() => {
  if (options.value.display === 'vertical') {
    return imageStyleVertical
  }

  if (options.value.display === 'aligned') {
    return imageStyleAligned
  }

  // 'left', 'right', 'horizontal'
  return imageStyleLeftRightHorizontal
})

const props = defineProps<{
  headline?: ParagraphTextImageFragment['headline']
  description?: ParagraphTextImageFragment['description']
  link?: ParagraphTextImageFragment['link']
  image?: ParagraphTextImageFragment['image']
}>()

/**
 * The special padding to move text elements out of the grid.
 */
const offsetPadding = computed(() => {
  if (options.value.display === 'left') {
    return 'lg:pl-32'
  } else if (options.value.display === 'right') {
    return 'lg:pr-24'
  }
})

const displayClass = computed(
  () => 'paragraph-text-image--' + options.value.display,
)

const url = computed(() => props.link?.uri?.path)
</script>

<style lang="postcss">
/* T = Title */
/* C = Content */
/* M = Media (image) */
.paragraph-text-image {
  grid-template-areas:
    '. T T T T .'
    '. C C C C .'
    'M M M M M M';
  @screen md {
    grid-template-areas:
      '. T T T T C C C C C C C C .'
      '. T T T T M M M M M M M M M';
  }
}

.paragraph-text-image--aligned {
  @screen lg {
    grid-template-areas:
      '. . . . . . T T T T T T T .'
      '. . . . . . C C C C C C C .'
      '. . . . . . M M M M M M M .';
  }
}

.paragraph-text-image--vertical {
  @screen lg {
    grid-template-areas:
      '. . . T T T T T T T T . . .'
      '. . . C C C C C C C C . . .'
      '. . . M M M M M M M M . . .';
  }
}

.paragraph-text-image--left {
  @screen lg {
    grid-template-rows: 1fr auto auto;
    grid-template-areas:
      'M M M M M M M M . . . . . .'
      'M M M M M M M M T T T T T .'
      'M M M M M M M M C C C C C .';
  }
}

.paragraph-text-image--right {
  @screen lg {
    grid-template-rows: 1fr auto auto;
    grid-template-areas:
      '. . . . . . M M M M M M M M'
      '. T T T T T M M M M M M M M'
      '. C C C C C M M M M M M M M';
  }
}

.paragraph-text-image--horizontal {
  @screen lg {
    grid-template-areas:
      '. T T T T T C C C C C C C .'
      '. T T T T T M M M M M M M M';
  }
}
</style>
